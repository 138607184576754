import { Injectable } from "@angular/core";
import { Howl, Howler } from "howler";

export const enum Sounds {
    ReceivedPoints = "points"
}

@Injectable({
    providedIn: "root"
})
export class SoundService {
    private sounds: any[] = [];
    constructor() {}

    preload() {
        this.sounds[Sounds.ReceivedPoints] = new Howl({
            src: ["/assets/sounds/points.mp3"]
        });
    }

    play(sound: Sounds) {
        this.sounds[sound].play();
    }
}
