import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, NgModule, NgZone } from "@angular/core";
import { Observable, Observer } from "rxjs";

@NgModule({})
export class NgZoneHttpInterceptorModule {
    static forRoot() {
        return {
            ngModule: NgZoneHttpInterceptorModule,
            providers: [{ provide: HTTP_INTERCEPTORS, useClass: NgZoneHttpInterceptor, multi: true }],
        };
    }
}

@Injectable()
export class NgZoneHttpInterceptor implements HttpInterceptor {
    constructor(private ngZone: NgZone) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.ngZone.run(() => {
            return next.handle(req).pipe(enterZone(this.ngZone));
        });
    }
}

function enterZone<T>(zone: NgZone) {
    return (source: Observable<T>) => {
        return new Observable((sink: Observer<T>) => {
            return source.subscribe({
                next(x) {
                    zone.run(() => sink.next(x));
                },
                error(e) {
                    zone.run(() => sink.error(e));
                },
                complete() {
                    zone.run(() => sink.complete());
                },
            });
        });
    };
}
