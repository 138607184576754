import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Observable } from "rxjs";
import { delayWhen, map } from "rxjs/operators";

import { Config } from "../config";
import { Achievement, LoginRequest, User, UserUpdateRequest } from "../definitions";
import { AppLoaderService } from "./app-loader.service";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class UserService {
    constructor(private baseService: BaseService, private platform: Platform, private appLoader: AppLoaderService) {}

    user(): Observable<User> {
        return this.baseService.get("user").pipe(map((response) => response.data));
    }
    logout() {
        return this.appLoader.updateApiToken(null);
    }
    delete(): Observable<any> {
        this.appLoader.clear();
        return this.baseService.delete("user").pipe(
            delayWhen((user) => {
                return this.appLoader.updateApiToken(null);
            })
        );
    }
    register(): Observable<User> {
        return this.baseService
            .post("user/register", {
                platform: this.platform.platforms()[0],
                language: navigator.language.split("-")[0],
                device_token: this.appLoader.deviceToken,
                app_version: Config.appVersion,
            })
            .pipe(
                map((response) => response.data),
                delayWhen((user) => {
                    return this.appLoader.updateApiToken(user.api_token);
                })
            );
    }
    achievements(): Observable<Achievement[]> {
        return this.baseService.get("user/achievements").pipe(map((response) => response.data));
    }
    useAchievement(achievement: Achievement): Observable<User> {
        return this.baseService.get(`user/achievements/${achievement.id}/use`).pipe(map((response) => response.data));
    }
    clearAchievements(): Observable<User> {
        return this.baseService.delete(`user/achievements`).pipe(map((response) => response.data));
    }
    login(params: LoginRequest): Observable<User> {
        return this.baseService.post("user/login", params).pipe(
            map((response) => response.data),
            delayWhen((user) => {
                return this.appLoader.updateApiToken(user.api_token);
            })
        );
    }

    update(params: UserUpdateRequest): Observable<User> {
        return this.baseService.put("user", params).pipe(map((response) => response.data));
    }

    redeem(code: string): Observable<User> {
        return this.baseService.post("prizes/claim", { code }).pipe(map((response) => response.data));
    }
}
