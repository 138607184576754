import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ChartjsModule } from "@ctrl/ngx-chartjs";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { MarkdownModule } from "ngx-markdown";
import { ZXingScannerModule } from "@zxing/ngx-scanner";

import { PipesModule } from "../pipes/pipes.module";
import { CardAlertComponent } from "./card-alert/card-alert.component";
import { CardMarkdownComponent } from "./card-markdown/card-markdown.component";
import { CardPlaceComponent } from "./card-place/card-place.component";
import { CardPrizeComponent } from "./card-prize/card-prize.component";
import { CardProfileComponent } from "./card-profile/card-profile.component";
import { FormQuestionsComponent } from "./form-questions/form-questions.component";
import { HeroComponent } from "./hero/hero.component";
import { InputImageComponent } from "./input-image/input-image.component";
import { ItemPostComponent } from "./item-post/item-post.component";
import { ListHighscoreComponent } from "./list-highscore/list-highscore.component";
import { StreamComponent } from "./list-stream/list-stream.component";
import { ViewBadgeComponent } from "./view-badge/view-badge.component";
import { ViewChartPieComponent } from "./view-chart-pie/view-chart-pie.component";
import { ViewEmptyComponent } from "./view-empty/view-empty.component";
import { ViewLoadingComponent } from "./view-loading/view-loading.component";
import { ViewTutorialComponent } from "./view-tutorial/view-tutorial.component";
import { ViewPointsComponent } from "./view-points/view-points.component";
import { PhotoSwipeComponent } from "./photo-swipe/photo-swipe.component";
import { ViewConfettiComponent } from "./view-confetti/view-confetti.component";
import { QrScannerComponent } from "./qr-scanner/qr-scanner.component";
import { PhotoEditorComponent } from "./photo-editor/photo-editor.component";
import { CardActionsComponent } from "./card-actions/card-actions.component";
import { PhotoComponent } from "./photo/photo.component";
import { PopoverAchievementComponent } from "./popover-achievement/popover-achievement.component";
import { AchievementComponent } from "./achievement/achievement.component";
import { ItemAchievementComponent } from "./item-achievement/item-achievement.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { ReportComponent } from "./report.component";
import { CardComponent } from "./card.component";
import { ViewIdeasComponent } from "./view-ideas.component";
import { DirectivesModule } from "../directives/directives.module";
import { PieController, Chart, ArcElement } from "chart.js";
import { ViewEventsComponent } from './view-events.component';
import { ItemEventComponent } from './item-event.component';
import { RouterModule } from "@angular/router";
import { ModalEventsComponent } from './modal-events.component';
import { EventFormComponent } from './event-form.component';
import { ItemElectionComponent } from './item-election.component';
import { ModalElectionComponent } from "./modal-elections.component";

Chart.register(PieController, ArcElement);

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PipesModule,
        MarkdownModule.forChild(),
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        TranslateModule,
        ChartjsModule,
        ZXingScannerModule,
        ImageCropperModule,
        DirectivesModule,
        RouterModule,
    ],
    exports: [
        CardProfileComponent,
        CardPlaceComponent,
        HeroComponent,
        StreamComponent,
        ItemPostComponent,
        CardMarkdownComponent,
        FormQuestionsComponent,
        ViewTutorialComponent,
        ViewEmptyComponent,
        CardPrizeComponent,
        ViewBadgeComponent,
        ListHighscoreComponent,
        CardAlertComponent,
        InputImageComponent,
        ViewLoadingComponent,
        ViewChartPieComponent,
        ViewPointsComponent,
        PhotoSwipeComponent,
        ViewConfettiComponent,
        QrScannerComponent,
        PhotoEditorComponent,
        CardActionsComponent,
        PhotoComponent,
        PopoverAchievementComponent,
        AchievementComponent,
        ItemAchievementComponent,
        ReportComponent,
        CardComponent,
        ViewIdeasComponent,
        ViewEventsComponent,
        ItemEventComponent,
        ModalEventsComponent,
        EventFormComponent,
        ItemElectionComponent,
        ModalElectionComponent,
    ],
    declarations: [
        CardProfileComponent,
        CardPlaceComponent,
        HeroComponent,
        StreamComponent,
        ItemPostComponent,
        CardMarkdownComponent,
        FormQuestionsComponent,
        ViewTutorialComponent,
        ViewEmptyComponent,
        CardPrizeComponent,
        ViewBadgeComponent,
        ListHighscoreComponent,
        CardAlertComponent,
        InputImageComponent,
        ViewLoadingComponent,
        ViewChartPieComponent,
        ViewPointsComponent,
        PhotoSwipeComponent,
        ViewConfettiComponent,
        QrScannerComponent,
        PhotoEditorComponent,
        CardActionsComponent,
        PhotoComponent,
        PopoverAchievementComponent,
        AchievementComponent,
        ItemAchievementComponent,
        ReportComponent,
        CardComponent,
        ViewIdeasComponent,
        ViewEventsComponent,
        ItemEventComponent,
        ModalEventsComponent,
        EventFormComponent,
        ModalElectionComponent,
        ItemElectionComponent,
    ],
})
export class ComponentsModule {}
