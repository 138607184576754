import { Injectable } from "@angular/core";
import { Preferences } from "@capacitor/preferences";
import { from } from "rxjs";
import { v4 as uuid } from "uuid";

import { Config } from "../config";

@Injectable({
    providedIn: "root",
})
export class AppLoaderService {
    public deviceToken: string = null;
    public apiToken: string = null;

    constructor() {}
    init(): Promise<void> {
        return new Promise(async (resolve, reject) => {
            await Preferences.migrate();
            await this.populateDeviceToken();
            this.apiToken = (await Preferences.get({ key: Config.appName + "_api_token" })).value;
            resolve();
        });
    }
    updateApiToken(apiToken: string) {
        this.apiToken = apiToken;
        if (apiToken === null) {
            return from(Preferences.remove({ key: Config.appName + "_api_token" }));
        } else {
            return from(Preferences.set({ key: Config.appName + "_api_token", value: apiToken }));
        }
    }
    clear() {
        const key = Config.appName + "_devicetoken";
        Preferences.remove({ key });
        this.populateDeviceToken();
    }
    private async populateDeviceToken() {
        const cookieKey = Config.appName + "_devicetoken";
        this.deviceToken = (await Preferences.get({ key: cookieKey })).value;
        if (!this.deviceToken) {
            this.deviceToken = uuid();
            Preferences.set({ key: cookieKey, value: this.deviceToken });
        }
    }
}
