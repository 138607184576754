import { State, Action, StateContext, Selector } from "@ngxs/store";
import { User, LoginRequest, UserUpdateRequest, Achievement, Transaction } from "../definitions";
import produce from "immer";
import { UserService } from "../services/user.service";
import { tap } from "rxjs/operators";
import { MessagingService } from "../services/messaging.service";
import { Injectable } from "@angular/core";
import { ImmutableContext } from "@ngxs-labs/immer-adapter";

export class DeleteUser {
    static readonly type = "[API] DeleteUser";
}

export class UserReceivedPoints {
    static readonly type = "[User] Received Points";
    constructor(public transaction: Transaction) {}
}
export class ResetPoints {
    static readonly type = "[User] ResetPoints";
    constructor() {}
}
export class GetUser {
    static readonly type = "[User] Get Current User";
    constructor() {}
}

export class UserLogin {
    static readonly type = "[User] Login";
    constructor(public params: LoginRequest) {}
}

export class DisableNotifications {
    static readonly type = "[API] DELETE Push Token";
    constructor() {}
}

export class UserLogout {
    static readonly type = "[User] Logout";
    constructor() {}
}
export class UserRegister {
    static readonly type = "[User] Register";
    constructor() {}
}

export class UserUpdate {
    static readonly type = "[User] Update";
    constructor(public params: UserUpdateRequest) {}
}
export class GetAchievements {
    static readonly type = "[User] Get Achievements";
    constructor() {}
}

export class UseAchievement {
    static readonly type = "[User] Use Achievement";
    constructor(public achievement: Achievement) {}
}
export class ClearAchievements {
    static readonly type = "[User] Clear Achievements";
    constructor() {}
}

export class UserRedeem {
    static readonly type = "[User] Redeem Prize";
    constructor(public code: string) {}
}

interface UserStateModel {
    user: User;
    achievements: Achievement[];
    transaction: Transaction;
}
@State<UserStateModel>({
    name: "user",
    defaults: {
        user: null,
        achievements: [],
        transaction: null,
    },
})
@Injectable()
export class UserState {
    @Selector()
    static transaction(state: UserStateModel) {
        return state.transaction;
    }
    @Selector()
    static user(state: UserStateModel) {
        return state.user;
    }
    @Selector()
    static achievements(state: UserStateModel) {
        return state.achievements;
    }

    constructor(private userService: UserService, private messagingService: MessagingService) {}

    @Action(DisableNotifications)
    async disableNotifications({ setState }: StateContext<UserStateModel>) {
        await this.messagingService.disableNotification();
    }

    @Action(UserLogout)
    userLogout(ctx: StateContext<UserStateModel>) {
        return this.userService.logout().pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = null;
                    })
                );
            })
        );
    }

    @Action(UserLogin)
    userLogin(ctx: StateContext<UserStateModel>, action: UserLogin) {
        return this.userService.login(action.params).pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = user;
                    })
                );
            })
        );
    }

    @Action(UserUpdate)
    userUpdate(ctx: StateContext<UserStateModel>, action: UserUpdate) {
        return this.userService.update(action.params).pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = user;
                    })
                );
            })
        );
    }

    @Action(UserRegister)
    userRegister(ctx: StateContext<UserStateModel>, action: UserRegister) {
        return this.userService.register().pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = user;
                    })
                );
            })
        );
    }
    @Action(GetAchievements)
    getAchievements(ctx: StateContext<UserStateModel>, action: GetAchievements) {
        return this.userService.achievements().pipe(
            tap((achievements) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.achievements = achievements;
                    })
                );
            })
        );
    }
    @Action(UseAchievement)
    useAchievement(ctx: StateContext<UserStateModel>, action: UseAchievement) {
        return this.userService.useAchievement(action.achievement).pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = user;
                    })
                );
            })
        );
    }
    @Action(ClearAchievements)
    clearAchievements(ctx: StateContext<UserStateModel>) {
        return this.userService.clearAchievements().pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = user;
                    })
                );
            })
        );
    }

    @Action(GetUser)
    getUser(ctx: StateContext<UserStateModel>) {
        return this.userService.user().pipe(
            tap((user) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.user = user;
                    })
                );
            })
        );
    }

    @Action(ResetPoints)
    reset(ctx: StateContext<UserStateModel>, action: ResetPoints) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.transaction = null;
            })
        );
    }

    @Action(UserReceivedPoints)
    points(ctx: StateContext<UserStateModel>, action: UserReceivedPoints) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.transaction = action.transaction;
            })
        );
    }
    @Action(DeleteUser)
    @ImmutableContext()
    deleteUser({ setState }: StateContext<UserStateModel>) {
        return this.userService.delete().pipe(
            tap((user) => {
                setState((state: UserStateModel) => {
                    state.user = null;
                    return state;
                });
            })
        );
    }
}
