import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import { ComponentsModule } from "../components/components.module";

import { QuestionsCreatePage } from "./questions-create/questions-create.page";
import { ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../directives/directives.module";

@NgModule({
    imports: [CommonModule, IonicModule, ComponentsModule, TranslateModule, ReactiveFormsModule, DirectivesModule],
    declarations: [QuestionsCreatePage],
    exports: [QuestionsCreatePage],
})
export class ModalsModule {}
