import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeModule, FaIconLibrary } from "@fortawesome/angular-fontawesome";

import {
    faThumbtack,
    faExclamationSquare,
    faNewspaper,
    faAward,
    faSignOut,
    faLightbulbOn,
    faUserCircle,
    faCity,
    faWrench,
    faCalendarAlt,
    faUserPlus,
    faQrcode,
    faCheckDouble,
    faCheck,
    faAlignLeft,
    faImage,
    faEmptySet,
    faCog,
    faTrashAlt,
    faChartPieAlt,
    faQuestion,
    faEdit,
    faGifts,
    faTrophy,
    faInfoCircle,
    faEngineWarning,
    faComments,
    faCalendar,
    faBuilding,
    faShareSquare,
    faBuildings,
} from "@fortawesome/pro-light-svg-icons";
import { faStar, faUserCog } from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";

import { ComponentsModule } from "./components/components.module";
import { PipesModule } from "./pipes/pipes.module";
import { ModalsModule } from "./modals/modals.module";
import { DirectivesModule } from "./directives/directives.module";

@NgModule({
    imports: [CommonModule, TranslateModule, ComponentsModule, PipesModule, FontAwesomeModule, ModalsModule, DirectivesModule],
    declarations: [],
    exports: [CommonModule, TranslateModule, ComponentsModule, PipesModule, FontAwesomeModule, ModalsModule, DirectivesModule],
})
export class SharedModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faEngineWarning,
            faComments,
            faThumbtack,
            faExclamationSquare,
            faStar,
            faNewspaper,
            faAward,
            faSignOut,
            faLightbulbOn,
            faUserCircle,
            faCity,
            faWrench,
            faShareSquare,
            faCalendarAlt,
            faUserCog,
            faUserPlus,
            faQrcode,
            faCheckDouble,
            faCheck,
            faCalendar,
            faAlignLeft,
            faImage,
            faEmptySet,
            faCog,
            faTrashAlt,
            faChartPieAlt,
            faQuestion,
            faEdit,
            faGifts,
            faTrophy,
            faInfoCircle,
            faLightbulbOn,
            faBuilding,
            faBuildings
        );
    }
}
