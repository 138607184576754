import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import { AlertController, Platform } from "@ionic/angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PushToken } from "../definitions";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root",
})
export class MessagingService {
    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private baseService: BaseService,
        private alertController: AlertController,
        private platform: Platform,
        private router: Router
    ) {}
    init() {
        this.receiveMessages();

        if (this.platform.is("hybrid")) {
            PushNotifications.addListener("registrationError", (error) => {
                console.log(error);
            });
        }
    }

    async requestPermission() {
        try {
            const token = await this.getRegisterToken();
            if (token) {
                await this.updateToken(token).toPromise();
            }
        } catch (error) {}
        return;
    }
    async disableNotification() {
        try {
            const token = await this.getRegisterToken();
            return await this.baseService.delete("push_token/" + token).toPromise();
        } catch (error) {
            console.log(error);
        }
    }
    private receiveMessages() {
        if (this.platform.is("hybrid")) {
            PushNotifications.addListener("pushNotificationReceived", (notification: any) => {
                console.log(notification);

                PushNotifications.removeAllDeliveredNotifications();
            });
            PushNotifications.addListener("pushNotificationActionPerformed", (notification) => {
                if (notification.notification && notification.notification.data && notification.notification.data.url) {
                    this.router.navigateByUrl(notification.notification.data.url);
                }
                PushNotifications.removeAllDeliveredNotifications();
            });
        } else {
            this.angularFireMessaging.messages.subscribe((payload: any) => {
                if (payload.notification) {
                    this.showNotification(payload.notification);
                }
            });
        }
    }
    private updateToken(token): Observable<PushToken> {
        return this.baseService.post("push_token", { token, type: this.getType() }).pipe(map((response) => response.data));
    }
    private getType() {
        let type = "web";
        if (this.platform.is("ios")) {
            type = "ios";
        } else if (this.platform.is("android")) {
            type = "android";
        }
        return type;
    }
    private async getRegisterToken() {
        if (this.platform.is("hybrid")) {
            return await this.getRegisterTokenNative();
        } else {
            return await this.getRegisterTokenWeb();
        }
    }
    private getRegisterTokenNative() {
        return new Promise<string>((resolve, reject) => {
            if (Capacitor.isPluginAvailable("PushNotifications")) {
                PushNotifications.requestPermissions().then((result) => {
                    if (result.receive === "granted") {
                        PushNotifications.addListener("registration", (token: Token) => {
                            resolve(token.value);
                        });
                        PushNotifications.register();
                    } else {
                        console.log("Error on registration: " + JSON.stringify(result));
                    }
                });
            }
        });
    }

    private getRegisterTokenWeb(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.angularFireMessaging.requestToken.subscribe(
                (token) => {
                    resolve(token);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    private async showNotification(notification) {
        if (!notification.title) {
            return;
        }
        const alert = await this.alertController.create({
            header: notification.title,
            message: notification.body,
            buttons: [
                {
                    text: "Ok",
                },
            ],
        });
        await alert.present();
    }
}
