<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons *ngIf="!isAcceptable" slot="start"> <ion-back-button [defaultHref]="'/app/profile'"></ion-back-button> </ion-buttons>
    <ion-title>{{ (content$ | async)?.title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-card>
    <div card-body class="prose max-w-none" [innerHTML]="(content$ | async)?.content"></div>
  </app-card>
</ion-content>
<ion-footer *ngIf="isAcceptable">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="onClose()"> {{ "Ablehnen" | translate }} </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="onAccept()" fill="outline" color="primary"> {{ "Akzeptieren" | translate }} </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
