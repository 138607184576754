import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginGuard } from "./guards/login.guard";
import { TermsPage } from "./pages/terms/terms.page";

const routes: Routes = [
    { path: "p/:page", loadChildren: () => import("./pages/content/content.module").then((m) => m.ContentPageModule) },
    { path: "join/:code", loadChildren: () => import("./pages/places/place-join/place-join.module").then((m) => m.PlaceJoinPageModule) },
    { path: "login", canActivate: [], loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule) },
    {
        path: "onboarding",
        canActivate: [LoginGuard],
        loadChildren: () => import("./pages/onboarding/onboarding.module").then((m) => m.OnboardingPageModule),
    },
    { path: "terms", component: TermsPage },
    {
        path: "tour",
        loadChildren: () => import("./pages/tour/tour.module").then((m) => m.TourPageModule),
    },
    { path: "", canActivate: [LoginGuard], loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule) },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
