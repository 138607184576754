import { Component, ViewChild, ElementRef, Input } from "@angular/core";

// Import PhotoSwipe
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import { TranslateService } from "@ngx-translate/core";

// Image Interface
export interface PhotoSwipeImage {
    src: string;
    w?: number;
    h?: number;
}

@Component({
    selector: "app-photo-swipe",
    templateUrl: "./photo-swipe.component.html",
    styles: [
        `
            ::ng-deep .pswp__top-bar {
                top: env(safe-area-inset-top);
            }
            ::ng-deep .pswp__share-tooltip {
                top: env(safe-area-inset-top);
            }
        `
    ]
})
export class PhotoSwipeComponent {
    @ViewChild("photoSwipe", { static: true }) photoSwipe: ElementRef;

    @Input() images: PhotoSwipeImage[] = [];

    // ========================================================================
    constructor(private translate: TranslateService) {}

    // ========================================================================
    async openGallery(images?: PhotoSwipeImage[], index: number = 0) {
        images = images || this.images;
        const options = {
            index,
            shareEl: true,
            fullscreenEl: false,
            shareButtons: [
                { id: "download", label: await this.translate.get("Bild speichern").toPromise(), url: "{{raw_image_url}}", download: true }
            ]
        };

        // Initializes and opens PhotoSwipe
        const gallery = new PhotoSwipe(this.photoSwipe.nativeElement, PhotoSwipeUI_Default, images, options);
        gallery.init();
    }
    // ========================================================================
}
