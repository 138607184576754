import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Post, QuestionAnswerRequest } from "../definitions";
import { BaseService } from "./base.service";

@Injectable({
    providedIn: "root"
})
export class StreamService {
    constructor(private baseService: BaseService) {}

    post(post: Partial<Post>): Observable<Post> {
        return this.baseService.get("posts/" + post.id).pipe(map(response => response.data));
    }

    stats(post: Partial<Post>): Observable<any> {
        return this.baseService.get("posts/" + post.id + "/stats").pipe(map(response => response.data));
    }

    answer(post: Partial<Post>, params: QuestionAnswerRequest): Observable<any> {
        return this.baseService.post(`posts/${post.id}/answers`, params).pipe(map(response => response.data));
    }

    posts(page: number = 1) {
        return this.baseService.get(`stream?page=${page}`).pipe(map(response => response.data));
    }
}
