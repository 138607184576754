import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from "@angular/core";

@Component({
    selector: "app-card",
    template: `
        <div class="flex-shrink-0">
            <ng-content select="[card-header]"></ng-content>
        </div>

        <div class="flex-1 bg-white p-6 flex flex-col justify-between ">
            <div class="flex-1">
                <ng-content select="[card-body]"></ng-content>
            </div>
            <ng-content select="[card-footer]"></ng-content>
        </div>
        <ng-content></ng-content>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnInit {
    @Input("class") class: string = "";

    @HostBinding("class")
    get hostClasses(): string {
        return [this.class, "flex flex-col rounded-lg shadow-lg overflow-hidden mb-6"].join(" ");
    }

    constructor() {}

    ngOnInit(): void {}
}
