import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StickyDirective } from "./sticky.directive";
import { PlaceAdminDirective } from "./place-admin.directive";
import { PlacePremiumDirective } from "./place-premium.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [StickyDirective, PlaceAdminDirective, PlacePremiumDirective],
    exports: [StickyDirective, PlaceAdminDirective, PlacePremiumDirective]
})
export class DirectivesModule {}
