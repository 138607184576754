import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import produce from "immer";
import { tap } from "rxjs/operators";

import { Place, Post, PostRequest, Question, QuestionAnswerRequest, StreamView, Transaction } from "../definitions";
import { PlacesService } from "../services/places.service";
import { StreamService } from "../services/stream.service";
import { UserReceivedPoints } from "./user.state";

export class GetStream {
    static readonly type = "[Stream] Get Stream ";
    constructor(public page: number = 1) {}
}
export class GetPlaceStream {
    static readonly type = "[Place] Get Stream ";
    constructor(public place: Partial<Place>, public page: number = 1, public streamView: StreamView = StreamView.All) {}
}

export class ClearPlaceStream {
    static readonly type = "[Place] Clear Place Stream ";
    constructor() {}
}

export class ClearSelectedPost {
    static readonly type = "[Post] Clear selected Post";
    constructor() {}
}

export class CreatePost {
    static readonly type = "[Place] Create a new Post";
    constructor(public place: Partial<Place>, public post: PostRequest) {}
}
export class UpdatePost {
    static readonly type = "[Place] Update a Post";
    constructor(public place: Partial<Place>, public post: Partial<Post>, public request: PostRequest) {}
}

export class DeletePost {
    static readonly type = "[Place] Delete Post";
    constructor(public place: Partial<Place>, public post: Partial<Post>) {}
}

export class GetPost {
    static readonly type = "[Post] Get Post";
    constructor(public post: Partial<Post>) {}
}

export class GetPostStats {
    static readonly type = "[Post] Get Post Stats";
    constructor(public post: Partial<Post>) {}
}
export class ReorderQuestions {
    static readonly type = "[Post] Reorder Questions";
    constructor(public from: number, public to: number) {}
}

export class AddQuestion {
    static readonly type = "[Post] Add Question";
    constructor(public question: Question) {}
}
export class UpdateQuestion {
    static readonly type = "[Post] Update Question";
    constructor(public question: Question) {}
}
export class RemoveQuestion {
    static readonly type = "[Post] Remove Question";
    constructor(public question: Question) {}
}

export class ReadPost {
    static readonly type = "[Post] Read Place Post";
    constructor(public place: Partial<Place>, public post: Partial<Post>) {}
}
export class LikePost {
    static readonly type = "[Post] Like Place Post";
    constructor(public place: Partial<Place>, public post: Partial<Post>) {}
}

export class SubmitAnswers {
    static readonly type = "[Post] Submit Answers";
    constructor(public post: Partial<Post>, public params: QuestionAnswerRequest) {}
}

export class GetPostInviteCode {
    static readonly type = "[Post] Get Post Invite Code";
    constructor(public post: Partial<Post>) {}
}

interface StreamStateModel {
    posts: Post[];
    place_posts: Post[];
    selected_post: Partial<Post>;
}
@State<StreamStateModel>({
    name: "stream",
    defaults: {
        posts: [],
        place_posts: [],
        selected_post: null,
    },
})
@Injectable()
export class StreamState {
    @Selector()
    static posts(state: StreamStateModel) {
        return state.posts;
    }
    @Selector()
    static selectedPost(state: StreamStateModel) {
        return state.selected_post;
    }

    @Selector()
    static placePosts(state: StreamStateModel) {
        return state.place_posts;
    }

    constructor(private streamService: StreamService, private placeService: PlacesService) {}

    @Action(ClearPlaceStream)
    clearSelectedPlace(ctx: StateContext<StreamStateModel>) {
        return this.placeService.places().pipe(
            tap((places) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place_posts = [];
                    })
                );
            })
        );
    }

    @Action(GetPlaceStream)
    placeStream(ctx: StateContext<StreamStateModel>, action: GetPlaceStream) {
        return this.placeService.stream(action.place, action.page, action.streamView).pipe(
            tap((posts) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        if (action.page === 1) {
                            draft.place_posts = [];
                        }
                        draft.place_posts = [...(<Post[]>draft.place_posts), ...posts];
                    })
                );
            })
        );
    }

    @Action(GetStream)
    stream(ctx: StateContext<StreamStateModel>, action: GetStream) {
        return this.streamService.posts(action.page).pipe(
            tap((posts) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        if (action.page === 1) {
                            draft.posts = [];
                        }
                        draft.posts.push(...posts);
                    })
                );
            })
        );
    }

    @Action(GetPost)
    get(ctx: StateContext<StreamStateModel>, action: GetPost) {
        return this.streamService.post(action.post).pipe(
            tap((post) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        post.hideQuestions = post.questions.findIndex((item) => item.user_answers.length !== 0) === -1 ? false : true;
                        draft.selected_post = { ...post };

                        const shuffleArray = (a) => {
                            const array = [...a];
                            for (let i = array.length - 1; i > 0; i--) {
                                const j = Math.floor(Math.random() * (i + 1));
                                [array[i], array[j]] = [array[j], array[i]];
                            }
                            return array;
                        };

                        for (const question of post.questions) {
                            question.shuffled_answers = shuffleArray(question.answers);
                        }
                    })
                );
            })
        );
    }
    @Action(DeletePost)
    delete(ctx: StateContext<StreamStateModel>, action: DeletePost) {
        return this.placeService.deletePost(action.place, action.post).pipe(
            tap((post) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.selected_post = null;
                        draft.posts = draft.posts.filter((item) => item.id !== action.post.id);
                        draft.place_posts = draft.place_posts.filter((item) => item.id !== action.post.id);
                    })
                );
            })
        );
    }

    @Action(GetPostStats)
    stats(ctx: StateContext<StreamStateModel>, action: GetPostStats) {
        return this.streamService.stats(action.post).pipe(
            tap((stats) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.selected_post.readCount = stats.read;
                        for (const question of stats.questions) {
                            const selectedQuestion = draft.selected_post.questions.find((item) => item.id === question.id);
                            for (const answer of question.answers) {
                                const selectedAnswer = selectedQuestion.answers.find((item) => item.id === answer.id);
                                selectedAnswer.count = answer.count;
                                selectedAnswer.user_answers = [];

                                if (answer.texts) {
                                    selectedAnswer.user_answers.push(answer.texts);
                                }
                                if (answer.images) {
                                    selectedAnswer.user_answers.push(answer.images);
                                }
                            }
                        }
                    })
                );
            })
        );
    }

    @Action(ClearSelectedPost)
    clear(ctx: StateContext<StreamStateModel>, action: ClearSelectedPost) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.selected_post = {
                    questions: [],
                };
            })
        );
    }

    @Action(AddQuestion)
    add(ctx: StateContext<StreamStateModel>, action: AddQuestion) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                action.question.id = Math.floor(Math.random() * 1000000000) + 1; // Random ID for local!
                draft.selected_post.questions.push(action.question);
            })
        );
    }

    @Action(UpdateQuestion)
    update(ctx: StateContext<StreamStateModel>, action: AddQuestion) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                const index = draft.selected_post.questions.findIndex((item) => item.id === action.question.id);
                draft.selected_post.questions[index] = action.question;
            })
        );
    }

    @Action(RemoveQuestion)
    remove(ctx: StateContext<StreamStateModel>, action: AddQuestion) {
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.selected_post.questions = draft.selected_post.questions.filter((question) => question.id !== action.question.id);
            })
        );
    }
    @Action(LikePost)
    likePlacePost(ctx: StateContext<StreamStateModel>, action: LikePost) {
        return this.placeService.likePlacePost(action.place, action.post).pipe(
            tap((likedPost: Post) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.selected_post.liked = likedPost.liked;
                        draft.selected_post.likes = likedPost.likes;
                        const post = draft.posts.find((item) => item.id === action.post.id);
                        if (post) {
                            post.liked = likedPost.liked;
                            post.likes = likedPost.likes;
                        }
                        const place_post = draft.place_posts.find((item) => item.id === action.post.id);
                        if (place_post) {
                            place_post.liked = likedPost.liked;
                            place_post.likes = likedPost.likes;
                        }
                    })
                );
            })
        );
    }
    @Action(ReadPost)
    readPlacePost(ctx: StateContext<StreamStateModel>, action: ReadPost) {
        return this.placeService.readPlacePost(action.place, action.post).pipe(
            tap((transaction: Transaction) => {
                if (!transaction) {
                    return;
                }
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        ctx.dispatch(new UserReceivedPoints(transaction));

                        draft.selected_post.claimed_points += transaction.points;
                        draft.selected_post.read = true;

                        const post = draft.posts.find((item) => item.id === action.post.id);
                        if (post) {
                            post.claimed_points += transaction.points;
                            post.read = true;
                        }
                        const place_post = draft.place_posts.find((item) => item.id === action.post.id);
                        if (place_post) {
                            place_post.claimed_points += transaction.points;
                            place_post.read = true;
                        }
                    })
                );
            })
        );
    }
    @Action(SubmitAnswers)
    submitanswers(ctx: StateContext<StreamStateModel>, action: SubmitAnswers) {
        return this.streamService.answer(action.post, action.params).pipe(
            tap((transaction: Transaction) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        ctx.dispatch(new UserReceivedPoints(transaction));

                        draft.selected_post.hideQuestions = true;
                        draft.selected_post.claimed_points += transaction.points;

                        const post = draft.posts.find((item) => item.id === action.post.id);
                        if (post) {
                            post.claimed_points += transaction.points;
                        }

                        const place_post = draft.place_posts.find((item) => item.id === action.post.id);
                        if (place_post) {
                            place_post.claimed_points += transaction.points;
                        }
                    })
                );
            })
        );
    }

    @Action(CreatePost)
    createPost(ctx: StateContext<StreamStateModel>, action: CreatePost) {
        return this.placeService.createPost(action.place, action.post).pipe(
            tap((post) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.place_posts.unshift(post);
                        draft.selected_post = post;
                    })
                );
            })
        );
    }
    @Action(ReorderQuestions)
    reorderQuestions(ctx: StateContext<StreamStateModel>, action: ReorderQuestions) {
        console.log(action);
        ctx.setState(
            produce(ctx.getState(), (draft) => {
                draft.selected_post.questions.splice(action.to, 0, draft.selected_post.questions.splice(action.from, 1)[0]);
            })
        );
    }

    @Action(UpdatePost)
    updatePost(ctx: StateContext<StreamStateModel>, action: UpdatePost) {
        return this.placeService.updatePostPost(action.place, action.post, action.request).pipe(
            tap((post) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.selected_post = post;
                    })
                );
            })
        );
    }
    @Action(GetPostInviteCode)
    getPostInviteCode(ctx: StateContext<StreamStateModel>, action: GetPostInviteCode) {
        return this.placeService.getPostInviteCode(action.post).pipe(
            tap((params) => {
                ctx.setState(
                    produce(ctx.getState(), (draft) => {
                        draft.selected_post.code = params.code;
                        draft.selected_post.qrcodeURL = params.qrcodeURL;
                    })
                );
            })
        );
    }
}
