import { Component, OnInit } from "@angular/core";
import { NavParams } from "@ionic/angular";
import { Achievement } from "../../definitions";

@Component({
    selector: "app-popover-achievement",
    templateUrl: "./popover-achievement.component.html",
    styleUrls: ["./popover-achievement.component.scss"],
})
export class PopoverAchievementComponent {
    achievement: Achievement = null;
    constructor(private params: NavParams) {
        this.achievement = params.data.achievement;
    }
}
