export const environment = {
    name: "production",
    production: true,
    backendEndpoint: "https://pimpyourtown.orly.app/",
    apiEndpoint: "https://pimpyourtown.orly.app/api/v1/",
    // backendEndpoint: "https://placem.orly.app/",
    // apiEndpoint: "https://placem.orly.app/api/v1/",

    serviceWorker: true,
    googleMapsAPIKey: "AIzaSyCRqANWh4X42_zIkw8sViN0RFFQaxzZ44Q",
    firebase: {
        apiKey: "AIzaSyD3oNEzDAgWza7hUiYm8GXH9_QzW6YiaAU",
        authDomain: "placem-6f8d9.firebaseapp.com",
        databaseURL: "https://placem-6f8d9.firebaseio.com",
        projectId: "placem-6f8d9",
        storageBucket: "placem-6f8d9.appspot.com",
        messagingSenderId: "597500613452",
        appId: "1:597500613452:web:4b6d1c612ca0a4063535e9",
    },
};
