<ng-progress color="#fff" debounceTime="200"></ng-progress>
<ion-app class="scanner-hide"> <ion-router-outlet></ion-router-outlet> <app-photo-swipe #photoSwipe></app-photo-swipe></ion-app>

<div class="scanner-ui">
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title>QR-Code scannen</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="cancelCamera()"> Abbrechen </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</div>
