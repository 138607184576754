import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { TermsPage } from "./terms.page";
import { MarkdownModule } from "ngx-markdown";
import { SharedModule } from "../../shared.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, MarkdownModule, SharedModule, TranslateModule],
    declarations: [TermsPage],
})
export class TermsPageModule {}
