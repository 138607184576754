import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { BaseService } from "../../services/base.service";

@Component({
    selector: "app-terms",
    templateUrl: "./terms.page.html",
})
export class TermsPage {
    isAcceptable = false;
    content$: Observable<any> = null;

    constructor(private modalController: ModalController, private baseService: BaseService) {
        this.content$ = this.baseService.get("pages/terms").pipe(
            map((response: any) => response.data),
            shareReplay()
        );
    }

    onAccept() {
        this.modalController.dismiss({
            accepted: true,
        });
    }
    onClose() {
        this.modalController.dismiss({
            accepted: false,
        });
    }
}
