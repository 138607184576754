import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ParseDatePipe } from "./parse-date.pipe";
import { TimeAgoPipe } from "./time-ago.pipe";
import { CallbackPipe } from "./callback.pipe";
import { SafePipe } from "./safe.pipe";

@NgModule({
    imports: [CommonModule],
    exports: [ParseDatePipe, TimeAgoPipe, CallbackPipe, SafePipe],
    declarations: [ParseDatePipe, TimeAgoPipe, CallbackPipe, SafePipe],
})
export class PipesModule {}
