import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { AppLoaderService } from "../services/app-loader.service";

@Injectable({
    providedIn: "root",
})
export class LoginGuard implements CanActivate {
    public static previousUrl: string = null;
    constructor(private router: Router, private appLoader: AppLoaderService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (state.url === "/onboarding") {
            if (this.appLoader.apiToken != null) {
                console.log("api token not null");
                this.router.navigateByUrl("/");
                return false;
            } else {
                return true;
            }
        } else {
            if (this.appLoader.apiToken) {
                if (LoginGuard.previousUrl) {
                    if (LoginGuard.previousUrl !== state.url) {
                        this.router.navigateByUrl(LoginGuard.previousUrl, { replaceUrl: true });
                    }
                    LoginGuard.previousUrl = null;
                }
                return true;
            } else {
                // save the url to redirect after login
                LoginGuard.previousUrl = state.url;
                console.log("onboarding", state.url);
                this.router.navigateByUrl("/onboarding");
                return false;
            }
        }
    }
}
