import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Platform, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";

import { PhotoSwipeComponent } from "./components/photo-swipe/photo-swipe.component";
import { Transaction } from "./definitions";
import { MessagingService } from "./services/messaging.service";
import { SocketService } from "./services/socket.service";
import { Sounds, SoundService } from "./services/sound.service";
import { ResetPoints, UserState } from "./stores/user.state";
import { FirebaseDynamicLinks } from "@pantrist/capacitor-firebase-dynamic-links";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
})
export class AppComponent {
    @Select(UserState.transaction) transaction$: Observable<Transaction>;

    @ViewChild("photoSwipe", { static: true }) photoSwipe: PhotoSwipeComponent;

    constructor(
        private translate: TranslateService,
        private store: Store,
        private soundService: SoundService,
        private toastController: ToastController,
        private swUpdate: SwUpdate,
        private messagingService: MessagingService,
        private socketService: SocketService,
        private router: Router,
        private platform: Platform
    ) {
        this.messagingService.init();
        this.socketService.init();
        this.initializeApp();
        this.soundService.preload();
    }

    initializeApp() {
        if (this.translate.getBrowserLang() !== "de") {
            this.translate.setDefaultLang("en");
            this.translate.use(this.translate.getBrowserLang());
        } else {
            this.translate.use("de");
        }

        if (Capacitor.isPluginAvailable("App")) {
            App.addListener("appUrlOpen", (data: any) => {
                if (data && data.url) {
                    const url = new URL(data.url);

                    console.log("App opened with URL: " + url.pathname);
                    setTimeout(() => {
                        this.router
                            .navigateByUrl(url.pathname + url.search)
                            .then((data) => console.log("redirect"))
                            .catch((e) => {
                                console.log("Route not found, redirection stopped with no error raised");
                            });
                    }, 1000);
                }
            });
            FirebaseDynamicLinks.addListener("deepLinkOpen", (data) => {
                if (data && data.url) {
                    const url = new URL(data.url);
                    console.log(url);
                    console.log("FirebaseDynamicLinks App opened with URL: " + url.pathname, url.search);
                    setTimeout(() => {
                        this.router
                            .navigateByUrl(url.pathname + url.search)
                            .then((data) => console.log("redirect"))
                            .catch((e) => {
                                console.log("Route not found, redirection stopped with no error raised");
                            });
                    }, 1000);
                }
            });
        }

        if (Capacitor.isPluginAvailable("SplashScreen")) {
            SplashScreen.hide();
        }
        if (Capacitor.isPluginAvailable("Keyboard")) {
            Keyboard.setAccessoryBarVisible({ isVisible: true });
        }

        window.addEventListener("keyboardDidHide", () => {
            window.scrollTo(0, 0);
            document.body.scrollTop = 0;
        });

        if (Capacitor.isPluginAvailable("StatusBar")) {
            StatusBar.setStyle({ style: Style.Dark });

            if (this.platform.is("android")) {
                StatusBar.setBackgroundColor({
                    color: "#DD4830",
                });
            }
        }

        this.transaction$.pipe(filter(Boolean)).subscribe(async (transaction: Transaction) => {
            if (transaction.points === 0 && transaction.exp === 0) {
                // Kein Hinweis anzeigen (z.B. Frage falsch beantwortet)
                return;
            }
            let message = "";
            if (transaction.points !== 0) {
                message += `+ ${transaction.points} Prämienpunkte `;
            }
            if (transaction.exp !== 0) {
                message += `+ ${transaction.exp} Erfahrungspunkte`;
            }

            const toast = await this.toastController.create({
                message: `Danke für Deine Beteiligung (${message})`,
                position: "bottom",
                duration: 3000,
                color: "primary",
                translucent: true,
            });
            toast.present();

            this.soundService.play(Sounds.ReceivedPoints);
            this.store.dispatch(new ResetPoints());
        });

        if (this.swUpdate.isEnabled) {
            this.swUpdate.checkForUpdate();
        }

        this.swUpdate.available.subscribe(async (evt) => {
            await this.swUpdate.activateUpdate();

            const toast = await this.toastController.create({
                message: "Update wurde erfolgreich installiert",
                buttons: [
                    {
                        text: "Neu starten",
                        handler: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
            await toast.present();
        });
    }
    cancelCamera() {
        document.body.classList.remove("qrscanner");
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    }
}
