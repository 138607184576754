import { Injectable } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Select, Store } from "@ngxs/store";
import Echo from "laravel-echo";
import { Observable } from "rxjs";

import { environment } from "../../environments/environment";
import { PopoverAchievementComponent } from "../components/popover-achievement/popover-achievement.component";
import { User, Achievement } from "../definitions";
import { UserState, GetUser } from "../stores/user.state";
import { AppLoaderService } from "./app-loader.service";

@Injectable({
    providedIn: "root"
})
export class SocketService {
    @Select(UserState.user) user$: Observable<User>;

    private connection = null;
    constructor(private popoverController: PopoverController, private store: Store, private appLoader: AppLoaderService) {
        if (this.appLoader.apiToken) {
            this.store.dispatch(new GetUser());
        }
    }
    init() {
        this.user$.subscribe(user => {
            if (user === null && this.connection) {
                this.connection.disconnect();
            } else if (user && this.connection === null) {
                this.connect(user);
            }
        });
    }
    private connect(user: User) {
        this.connection = new Echo({
            broadcaster: "pusher",
            key: "9ceb5f64a888cddedb7e",
            cluster: "eu",
            encrypted: true,
            authEndpoint: environment.backendEndpoint + "api/v1/broadcasting/auth",
            auth: { headers: { Authorization: "Bearer " + user.api_token } }
        });

        this.connection.private("App.User." + user.id).notification(notification => {
            console.log(notification);

            this.presentPopover(notification);
        });
    }

    async presentPopover(achievement: Achievement) {
        const popover = await this.popoverController.create({
            component: PopoverAchievementComponent,
            translucent: true,
            animated: true,
            componentProps: { achievement }
        });

        await popover.present();
    }
}
