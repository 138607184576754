import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import lcoaleDe from "@angular/common/locales/de";
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import Bugsnag, { Event } from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxsEmitPluginModule } from "@ngxs-labs/emitter";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsModule } from "@ngxs/store";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { ClipboardModule } from "ngx-clipboard";
import { MarkdownModule, MarkedOptions, MarkedRenderer } from "ngx-markdown";
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { Config } from "./config";
import { NgZoneHttpInterceptorModule } from "./NgZoneHttpInterceptor";
import { TermsPageModule } from "./pages/terms/terms.module";
import { AppLoaderService } from "./services/app-loader.service";
import { PlacesState } from "./stores/places.state";
import { StreamState } from "./stores/stream.state";
import { UserState } from "./stores/user.state";

registerLocaleData(lcoaleDe);
declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
        ngModule: Type<T>;
        providers?: Provider[];
    }
}

declare global {
    interface Window {
        bugsnagClient: any;
    }
}

Bugsnag.start({
    apiKey: "1236da9d5eb653a878df8e4f592e7f23",
    releaseStage: environment.production ? "production" : "development",
    appVersion: Config.appVersion,
    collectUserIp: false,
    onError: (event: Event) => {
        // Bugsnag soll Custom Exception und HTTP Fehler ignorieren
        // Bissel doof aber anders bekomme ich das gerade nicht hin
        if (event.errors[0].errorClass === "[no error class]" || event.errors[0].errorClass === "HttpErrorResponse") {
            return false;
        }
    },
});

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(window.bugsnagClient);
}

export function init(appLoadService: AppLoaderService) {
    return () => appLoadService.init();
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
    const renderer = new MarkedRenderer();

    renderer.em = (text: string) => {
        return text;
    };

    return {
        renderer: renderer,
        gfm: true,
        breaks: false,
        pedantic: false,
        smartLists: true,
        smartypants: false,
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot({
            mode: "md",
            swipeBackEnabled: true,
        }),
        MarkdownModule.forRoot({
            markedOptions: {
                provide: MarkedOptions,
                useFactory: markedOptionsFactory,
                useValue: {
                    gfm: true,
                    tables: true,
                    breaks: true,
                    pedantic: false,
                    sanitize: false,
                    smartLists: true,
                    smartypants: false,
                },
            },
        }),
        NgZoneHttpInterceptorModule.forRoot(),
        NgProgressModule.withConfig({}),
        NgProgressHttpModule.withConfig({}),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AppRoutingModule,
        ClipboardModule,
        NgxsModule.forRoot([PlacesState, StreamState, UserState]),
        NgxsEmitPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        ZXingScannerModule,
        ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.serviceWorker }),
        ComponentsModule,
        TermsPageModule,
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_INITIALIZER, useFactory: init, deps: [AppLoaderService], multi: true },
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        { provide: LOCALE_ID, useValue: "de" },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
